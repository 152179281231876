import React, { useEffect, useState } from 'react';
import './HomePage.css';

// Import images
import logo from '../../images/logo.svg';
import aiagentstore from '../../images/aiagentstore.jpg';
import longsummary from '../../images/longsummary.jpg';
import skydis from '../../images/skydis.jpg';

const HomePage = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [formStatus, setFormStatus] = useState({
        submitting: false,
        success: null,
        message: '',
    });

    // Handle animations on scroll
    useEffect(() => {
        const observerOptions = {
            root: null,
            rootMargin: '0px',
            threshold: 0.1
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active');
                    observer.unobserve(entry.target);
                }
            });
        }, observerOptions);

        document.querySelectorAll('.fade-in').forEach(el => {
            observer.observe(el);
        });

        // Cleanup
        return () => {
            document.querySelectorAll('.fade-in').forEach(el => {
                observer.unobserve(el);
            });
        };
    }, []);

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Update the state to show the form is submitting
        setFormStatus({
            submitting: true,
            success: null,
            message: 'Sending your message...',
        });

        // Collect form data
        const formData = {
            name: e.target.name.value,
            email: e.target.email.value,
            subject: e.target.subject.value,
            message: e.target.message.value
        };

        try {
            // Replace with your deployed Firebase function URL
            const response = await fetch('https://YOUR_REGION-YOUR_PROJECT_ID.cloudfunctions.net/submitContactForm', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData)
            });

            const data = await response.json();

            if (response.ok) {
                // Success
                setFormStatus({
                    submitting: false,
                    success: true,
                    message: data.message || 'Your message has been sent successfully! We\'ll get back to you soon.',
                });
                e.target.reset();
            } else {
                // Error from the server
                setFormStatus({
                    submitting: false,
                    success: false,
                    message: data.error || 'There was an error sending your message. Please try again later.',
                });
            }
        } catch (error) {
            // Network or other error
            console.error('Error submitting form:', error);
            setFormStatus({
                submitting: false,
                success: false,
                message: 'Unable to connect to the server. Please check your internet connection and try again.',
            });
        }
    };

    // Toggle mobile menu
    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    // Smooth scroll to section
    const scrollToSection = (sectionId) => {
        document.querySelector(sectionId).scrollIntoView({
            behavior: 'smooth'
        });

        // Close mobile menu if open
        if (window.innerWidth <= 768) {
            setIsMenuOpen(false);
        }
    };

    return (
        <div className="home-page">
            <header>
                <div className="container header-container">
                    <a href="/" className="logo-container">
                        <img src={logo} alt="MB Skydis Logo" className="logo-img" />
                        <span className="logo-text">MB Skydis</span>
                    </a>
                    <nav className={`nav-links ${isMenuOpen ? 'open' : ''}`}>
                        <a href="#services" onClick={() => scrollToSection('#services')}>Services</a>
                        <a href="#products" onClick={() => scrollToSection('#products')}>Products</a>
                        <a href="#contact" onClick={() => scrollToSection('#contact')}>Contact</a>
                    </nav>
                    <button className="mobile-menu-btn" onClick={toggleMenu}>
                        ☰
                    </button>
                </div>
            </header>

            <section className="hero">
                <div className="container">
                    <div className="hero-content">
                        <h1>Transforming Businesses with Intelligent AI Automation</h1>
                        <p>We help companies streamline operations, boost productivity, and unlock new opportunities with custom AI solutions.</p>
                        <a href="#contact" className="btn" onClick={() => scrollToSection('#contact')}>Get in Touch</a>
                        <a href="#products" className="btn btn-secondary" onClick={() => scrollToSection('#products')}>Our Products</a>
                    </div>
                </div>
            </section>

            <section id="services" className="services">
                <div className="container">
                    <h2 className="fade-in">AI Automation Solutions</h2>
                    <p className="section-intro fade-in delay-1">
                        We specialize in developing intelligent AI automation systems that help businesses operate more efficiently, reduce manual tasks, and deliver better customer experiences.
                    </p>
                    <div className="services-grid">
                        <div className="service-card fade-in delay-1">
                            <div className="service-icon">🤖</div>
                            <h3 className="service-title">Custom AI Agents</h3>
                            <p>We design and develop personalized AI agents that perform specific tasks for your business, from customer service to data processing.</p>
                        </div>
                        <div className="service-card fade-in delay-2">
                            <div className="service-icon">📊</div>
                            <h3 className="service-title">AI Workflow Automation</h3>
                            <p>Our solutions automate complex workflows by integrating AI capabilities into your existing processes, eliminating repetitive tasks.</p>
                        </div>
                        <div className="service-card fade-in delay-3">
                            <div className="service-icon">💬</div>
                            <h3 className="service-title">Conversational AI</h3>
                            <p>We build intelligent conversational systems that understand context, learn from interactions, and provide meaningful responses.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section id="products" className="products">
                <div className="container">
                    <h2 className="fade-in">Our Products</h2>
                    <p className="section-intro fade-in delay-1">
                        We've developed a suite of innovative AI products to address common business challenges and enhance productivity.
                    </p>
                    <div className="products-grid">
                        <div className="product-card fade-in delay-1">
                            <div className="product-card-inner">
                                <div className="product-img-container">
                                    <img src={aiagentstore} alt="AI Agent Store" className="product-img" />
                                </div>
                                <div className="product-content">
                                    <h3 className="product-title">AI Agent Store</h3>
                                    <p className="product-description">The comprehensive AI agent marketplace and directory where businesses can discover, compare, and acquire specialized AI agents for various tasks. Our platform connects users with the perfect AI solutions for their specific needs.</p>
                                    <p className="product-description">AI Agent Store features a curated collection of ready-to-use AI agents, tools for building custom agents, and an innovative AI Agency list to help businesses find expert automation partners.</p>
                                    <a href="https://aiagentstore.ai" className="btn-product">Explore AI Agent Store</a>
                                </div>
                            </div>
                        </div>
                        <div className="product-card fade-in delay-2">
                            <div className="product-card-inner">
                                <div className="product-img-container">
                                    <img src={longsummary} alt="Long Summary" className="product-img" />
                                </div>
                                <div className="product-content">
                                    <h3 className="product-title">Long Summary</h3>
                                    <p className="product-description">An advanced text summarization tool that can handle documents of any length and generate summaries of any desired length, overcoming the limitations of traditional AI models.</p>
                                    <a href="https://longsummary.com" className="btn-product">Try Long Summary</a>
                                </div>
                            </div>
                        </div>
                        <div className="product-card fade-in delay-3">
                            <div className="product-card-inner">
                                <div className="product-img-container">
                                    <img src={skydis} alt="Skydis" className="product-img" />
                                </div>
                                <div className="product-content">
                                    <h3 className="product-title">Skydis</h3>
                                    <p className="product-description">Create powerful AI agents for your website in minutes. Engage visitors, qualify leads, and provide 24/7 intelligent assistance trained on your business data.</p>
                                    <a href="https://skydis.com" className="btn-product">Get Skydis</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="contact" className="contact">
                <div className="container contact-container">
                    <div className="contact-info fade-in">
                        <h3>Ready to Automate Your Business with AI?</h3>
                        <p>We specialize in custom AI automation projects tailored to your specific business needs. Get in touch to discuss how we can help transform your operations with intelligent AI solutions.</p>
                        <div className="contact-method">
                            <div className="contact-icon">✉️</div>
                            <div>
                                <strong>Email</strong>
                                <p>info@mbskydis.com</p>
                            </div>
                        </div>
                        <div className="contact-method">
                            <div className="contact-icon">🌐</div>
                            <div>
                                <strong>Website</strong>
                                <p>www.mbskydis.com</p>
                            </div>
                        </div>
                    </div>
                    <form className="contact-form fade-in delay-2" onSubmit={handleSubmit}>
                        {formStatus.success === true ? (
                            <div className="form-success">
                                <h3>Thank you!</h3>
                                <p>{formStatus.message}</p>
                                <button
                                    type="button"
                                    className="form-button"
                                    onClick={() => setFormStatus({ submitting: false, success: null, message: '' })}
                                >
                                    Send Another Message
                                </button>
                            </div>
                        ) : (
                            <>
                                <div className="form-group">
                                    <label htmlFor="name" className="form-label">Name</label>
                                    <input type="text" id="name" name="name" className="form-input" required disabled={formStatus.submitting} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="email" className="form-label">Email</label>
                                    <input type="email" id="email" name="email" className="form-input" required disabled={formStatus.submitting} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="subject" className="form-label">Subject</label>
                                    <input type="text" id="subject" name="subject" className="form-input" required disabled={formStatus.submitting} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="message" className="form-label">Message</label>
                                    <textarea id="message" name="message" className="form-textarea" required disabled={formStatus.submitting}></textarea>
                                </div>
                                {formStatus.success === false && (
                                    <div className="form-error-message">
                                        {formStatus.message}
                                    </div>
                                )}
                                <button type="submit" className="form-button" disabled={formStatus.submitting}>
                                    {formStatus.submitting ? 'Sending...' : 'Send Message'}
                                </button>
                            </>
                        )}
                    </form>
                </div>
            </section>

            <footer>
                <div className="container">
                    <div className="footer-grid">
                        <div className="footer-col">
                            <h4>MB Skydis</h4>
                            <p>Pioneering the future of business with intelligent AI automation solutions. We help companies streamline operations, boost productivity, and deliver exceptional experiences.</p>
                        </div>
                        <div className="footer-col">
                            <h4>Products</h4>
                            <ul className="footer-links">
                                <li><a href="https://aiagentstore.ai">AI Agent Store</a></li>
                                <li><a href="https://longsummary.com">Long Summary</a></li>
                                <li><a href="https://skydis.com">Skydis</a></li>
                            </ul>
                        </div>
                        <div className="footer-col">
                            <h4>Services</h4>
                            <ul className="footer-links">
                                <li><a href="#services" onClick={() => scrollToSection('#services')}>Custom AI Agents</a></li>
                                <li><a href="#services" onClick={() => scrollToSection('#services')}>AI Workflow Automation</a></li>
                                <li><a href="#services" onClick={() => scrollToSection('#services')}>Conversational AI</a></li>
                                <li><a href="#contact" onClick={() => scrollToSection('#contact')}>Custom Projects</a></li>
                            </ul>
                        </div>
                        <div className="footer-col">
                            <h4>Company</h4>
                            <ul className="footer-links">
                                <li><a href="#contact" onClick={() => scrollToSection('#contact')}>Contact</a></li>
                                <li><a href="/privacy-policy">Privacy Policy</a></li>
                                <li><a href="/terms-of-service">Terms of Service</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="footer-bottom">
                        <p>&copy; {new Date().getFullYear()} MB Skydis. All rights reserved.</p>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default HomePage;